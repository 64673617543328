import React, { useState, createContext, useEffect, useRef } from "react";
import Conf from 'Conf';
import CockpitSDK from "cockpit-sdk";
import AssetManager from 'utils/AssetManager';
import WebFont from 'webfontloader';
import Queue from "queue";
const cacheName = 'v2-parre';

const MobileDetect=require('mobile-detect');

const cockpit = new CockpitSDK({
  host: Conf.cockpitHost,
  accessToken: Conf.cockpitToken,
});

export const ModelContext = createContext({});
const loadList=[
  {
    id:'/images/cadre.png',
    url:'/images/cadre.png',
    cb:()=>{},
    css:true,
  },
  {
    id:'/images/cadre_noir.png',
    url:'/images/cadre_noir.png',
    cb:()=>{},
    css:true,
  },
];

let needPreload=true;
let needFetch=true;
const nbItems=10;

const startLang=localStorage.getItem("parre_lang") || 'fr';

const ModelProvider = ({children})=>{
  const [ cachedFiles,setCachedFiles ] = useState([]);
  const [ files,setFiles ] = useState([]);
  const [ appState,setAppState ] = useState({
    lang:startLang,
    landing:true,
    active:null,
    focus:0,
    dTop:0,
    dBottom:0,
    couleur:'#c8d2d5',
    menuOpen:false,
    page:1,
    showHelper:false,
  });
  const [ cockpitDone,setCockpitDone ] = useState(false);
  const [ modele,setModele ] = useState({
    isMobile:!((new MobileDetect(window.navigator.userAgent)).mobile()===null),
  });
  const cache=useRef(null);
  const Am=useRef(null);
  useEffect(()=>{
    if('caches' in window) {
      console.log('restore from cache');
      caches.open(cacheName)
      .then((c) => {
        cache.current=c;
        c.keys().then(function(response) {
          setCachedFiles(response);
        });
      });
    };
    Am.current=new AssetManager({
      onProgress:(p)=>setModele((state)=>{return{...state,preload:25+0.75*p}}),
    });
  },[]);
  useEffect(()=>{
    if (needFetch) {
      needFetch=false;
      let q=Queue({concurrency:1});
      q.push((cb)=>
        cockpit.singletonGet("Traductions").then(data => {
          setModele((state)=>{return {...state,Traductions:data,preload:25*1/nbItems}});
          return cb();
        })
      );
      q.push((cb)=>
        cockpit.singletonGet("Presentation").then(data => {
          setModele((state)=>{return {...state,Presentation:data,preload:25*2/nbItems}});
          return cb();
        })
      );
      q.push((cb)=>
        cockpit.singletonGet("Biographie").then(data => {
          setModele((state)=>{return {...state,Biographie:data,preload:25*3/nbItems}});
          return cb();
        })
      );
      q.push((cb)=>
        cockpit.singletonGet("Reflexions").then(data => {
          setModele((state)=>{return {...state,ReflexionsRub:data,preload:25*4/nbItems}});
          return cb();
        })
      );
      q.push((cb)=>
        cockpit.collectionGet("Reflexions", {}).then(data => {
          setModele((state)=>{return {...state,Reflexions:data.entries.sort((a,b)=>a._o-b._o),preload:25*5/nbItems}});
          return cb();
        })
      );
      q.push((cb)=>
        cockpit.singletonGet("Residences").then(data => {
          setModele((state)=>{return {...state,ResidencesRub:data,preload:25*6/nbItems}});
          return cb();
        })
      );
      q.push((cb)=>
        cockpit.collectionGet("Residences", {}).then(data => {
          setModele((state)=>{return {...state,Residences:data.entries.sort((a,b)=>a._o-b._o),preload:25*7/nbItems}});
          return cb();
        })
      );
      q.push((cb)=>
        cockpit.singletonGet("Message").then(data => {
          setModele((state)=>{return {...state,Message:data,preload:25*8/nbItems}});
          return cb();
        })
      );
      q.push((cb)=>
        cockpit.collectionGet("Oeuvres", {}).then(data => {
          setModele((state)=>{return {...state,Oeuvres:data.entries.sort((a,b)=>a._o-b._o),preload:25*9/nbItems}});
          return cb();
        })
      );
      q.push((cb)=>{
        console.log('fonts loading...');
        WebFont.load({
          custom: {
            families: ['InterFace-Bold','InterFace'],
          },
          active: ()=>{
            console.log('fonts loaded');
            cb();
            return null;
          }
        })
      });
      q.on('end', ()=> {
        setCockpitDone(true);
      });
      q.start();
    }
  },[setModele,setCockpitDone]);
  useEffect(()=>{
    if (cockpitDone && needPreload) {
      needPreload=false;
      console.log('all data fetched',modele);
      modele.Oeuvres.map((o)=>{
        // if (o.Video && o.Video.path){
        //   console.log('video to preload *',o.Video.path);
        //   loadList.push({
        //     id:o.Video.path,
        //     url:Conf.assetsUrl+o.Video.path,
        //     cb:(url)=>{}
        //   });
        // }
        if (o.Image_principale && o.Image_principale.path){
          console.log('image to preload',o.Image_principale.path);
          loadList.push({
            id:o.Image_principale.path,
            url:Conf.assetsUrl+o.Image_principale.path,
            cb:(url)=>{}
          });
        }
        if (o.Vignette_video && o.Vignette_video.path){
          console.log('image to preload',o.Vignette_video.path);
          loadList.push({
            id:o.Vignette_video.path,
            url:Conf.assetsUrl+o.Vignette_video.path,
            cb:(url)=>{}
          });
        }
        return null
      });
      modele.Residences.map((o)=>{
        if (o.Image && o.Image._id){
          const url=Conf.cockpitHost+'/api/cockpit/image?token='+Conf.cockpitToken+'&src='+o.Image._id+'&w=1000&h=350&m=thumbnail&o=true'
          console.log('image residence to preload',url);
          loadList.push({
            id:o.Image.path,
            url,
            cb:()=>{}
          });
        }
        return null
      });
      setModele((state)=>{return {...state,ready:true}});
      const list=[];
      const preload=[];
      const q= new Queue({concurrency:1});
      loadList.forEach((item) => {
        q.push((cb)=>{
          const cachedFile=cachedFiles.find((o)=>o.url.endsWith(item.url));
          if(cachedFile) {
            let size=0;
            cache.current.match(item.url)
            .then(response => {
              if (!response) {
                preload.push(item);
                cb();
                cache.current.delete(item.url).then(()=>{
                  console.log('cache entry deleted');
                });
                return null;
              }
              return response.blob()
            })
            .then(blob => {
              if (blob){
                size=blob.size;
                return URL.createObjectURL(blob);
              } return null;
            })
            .then((blobUrl)=>{
              if (blobUrl){
                list.push({...item, blobUrl, size});
                if (item.css) document.documentElement.style.setProperty('-'+item.url.replace(/[^a-zA-Z0-9]/g,'-'), `url(${blobUrl})`);
                cb();
              }
            })
          } else {
            preload.push(item);
            cb();
          }
        });
      });
      q.on('end',()=>{
        setFiles(state=>[...state,...list]);
        if (preload.length>0) {
          Am.current.fetch(preload,(items)=>{
            setFiles(state=>[...state,...items]);
            setModele((state)=>{return {...state,preload:100}})
            setTimeout(()=>setModele((state)=>{return {...state,preloaded:true}}),1000);
          });
        } else {
          setModele((state)=>{return {...state,preload:100}})
          setTimeout(()=>setModele((state)=>{return {...state,preloaded:true}}),1000);
        }
      });
      q.start();
    }
  },[cockpitDone,modele,cachedFiles]);
  const file=(url)=>{
    const f=files.find((o)=>o.url.endsWith(url));
    return f ? f.blobUrl : url;
  }
  const sendForm=(form,data,cb)=>{
    cockpit.formSubmit(form,data).then(res => {
      //console.log(res,cb);
      if (cb) cb();
      return null;
    });
  }
  useEffect(()=>{
    const handleResize=()=>{
      const sizes=[
        {name:'xl',size:1600},
        {name:'lg',size:1200},
        {name:'md',size:992},
        {name:'sm',size:768},
        {name:'xs',size:576},
      ];
      const w=window.innerWidth;
      let screenSize='xxl';
      sizes.forEach((item) => {
        if (item.size>w) screenSize=item.name;
      });
      setAppState(state=>{return{...state,screenSize}});
    }
    window.addEventListener('resize',handleResize);
    handleResize();
    return ()=>{
      window.removeEventListener('resize',handleResize);
    }
  })
  useEffect(()=>{
    localStorage.setItem('parre_lang',appState.lang);
  },[appState.lang])
  return (
    <ModelContext.Provider value={{modele,cockpitDone,appState,setAppState,files,cachedFiles,file,sendForm}}>
      {children}
    </ModelContext.Provider>
  );
}
export default ModelProvider;
