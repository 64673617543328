import React, { useContext,useEffect,useState } from 'react';
import 'components/Loader.scss';
import { ModelContext } from "providers/ModelProvider";

function Loader () {
  const { modele } = useContext(ModelContext);
  const [ show,setShow ] = useState(false);
  useEffect(()=>{
    const timer=setTimeout(()=>setShow(true),500);
    return ()=>clearTimeout(timer);
  },[]);
  return show ? <div className="loader">
      <svg viewBox="0 0 553.896 112.056"
      height="112.056"
      width="553.896">
        <defs
         id="defs7" />
        <clipPath id="clipping">
        <path
         transform="translate(-12.936 112.056)"
         d="M12.936,0V-112.056h35.28a107.678,107.678,0,0,1,15.876,1.092,47.473,47.473,0,0,1,13.86,4.284,26.049,26.049,0,0,1,12.6,12.012,37.29,37.29,0,0,1,4.032,17.052A39.933,39.933,0,0,1,93.24-67.452a38.379,38.379,0,0,1-3.864,9.492,35.157,35.157,0,0,1-6.216,7.9,28.1,28.1,0,0,1-8.4,5.544,53.994,53.994,0,0,1-11.928,3.276,81.088,81.088,0,0,1-12.264.924H35.112V0ZM35.112-59.64h12.6a70.089,70.089,0,0,0,8.82-.5,22.719,22.719,0,0,0,6.8-1.848,13.7,13.7,0,0,0,6.888-6.048,17.543,17.543,0,0,0,2.016-8.232,17.524,17.524,0,0,0-2.268-8.736,12.493,12.493,0,0,0-7.14-5.712,34.128,34.128,0,0,0-6.048-1.512,54.623,54.623,0,0,0-8.232-.5H35.112ZM104.5,0l46.032-112.056h15.96L213.192,0h-24.36L179.76-22.512H136.584L127.344,0Zm38.976-41.5h28.9L157.92-78.288ZM243.1,0V-112.056h45.528a67.179,67.179,0,0,1,14.112,1.512,36.056,36.056,0,0,1,12.684,5.292,29.481,29.481,0,0,1,9.24,10.164q3.612,6.384,3.612,16.3A43.864,43.864,0,0,1,326.76-67.2a34.187,34.187,0,0,1-4.62,10.164,31.811,31.811,0,0,1-8.064,8.148,39.276,39.276,0,0,1-11.844,5.544L330.96,0h-25.7l-27.72-42H265.272V0Zm22.176-61.32h17.3q12.264,0,17.808-3.864t5.544-11.424q0-8.064-5.208-12.012t-18.144-3.948h-17.3ZM364.56,0V-112.056h45.528a67.179,67.179,0,0,1,14.112,1.512,36.056,36.056,0,0,1,12.684,5.292,29.481,29.481,0,0,1,9.24,10.164q3.612,6.384,3.612,16.3A43.864,43.864,0,0,1,448.224-67.2a34.187,34.187,0,0,1-4.62,10.164,31.811,31.811,0,0,1-8.064,8.148A39.276,39.276,0,0,1,423.7-43.344L452.424,0h-25.7L399-42H386.736V0Zm22.176-61.32h17.3q12.264,0,17.808-3.864t5.544-11.424q0-8.064-5.208-12.012T404.04-92.568h-17.3ZM486.024,0V-112.056h76.944v20.328H508.7v25.536H553.56v20.328H508.7v25.536h58.128V0Z"
         data-name="Tracé 20"
         id="Tracé_20" />
        </clipPath>
      </svg>
      <div className="loader-wrapper">
        <div className="loader-inner" style={{width:modele.preload+"%"}}></div>
      </div>
  </div> : null ;
}
export default Loader;
