import React, {useContext} from "react";
import "./Biographie.scss";
import { ModelContext } from "providers/ModelProvider";
import ReactMarkdown from 'react-markdown';
import Utils from 'utils/Utils';

function Biographie() {
  const { modele,appState } = useContext(ModelContext);
  const { Biographie } = modele;
  const titrePrincipal=Utils.trad(Biographie,'Titre_Principal',appState.lang);
  const titre=Utils.trad(Biographie,'Titre',appState.lang);
  const texte=Utils.trad(Biographie,'Texte',appState.lang);
  return <div className='biographie'>
    <div className='biographie-inner'>
      <div className='biographie-titre-principal'>
        {titrePrincipal}
      </div>
      <div className="biographie-logo"/>
      <div className='biographie-titre'>
        {titre}
      </div>
      <div className='biographie-texte'>
        <ReactMarkdown>{texte}</ReactMarkdown>
      </div>
    </div>
  </div>;
}

export default Biographie;
