import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ModelProvider from "providers/ModelProvider";
import { BrowserRouter } from "react-router-dom";

if ('serviceWorker' in navigator) {
  console.log("serviceWorker found")
    navigator.serviceWorker
    .register('/service-worker.js', { scope: '/' })
    .then(function(registration) {
      console.log("Service Worker Registered");
    })
    .catch(function(err) {
      console.log("Service Worker Failed to Register", err);
    });
    navigator.serviceWorker.addEventListener('message', function(event) {
    });
} else {
  console.log("serviceWorker not found")
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <React.StrictMode>
      <ModelProvider>
        <App />
      </ModelProvider>
    </React.StrictMode>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
