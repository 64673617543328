import Preload from 'preload-it';
import fetch from "utils/CustomFetch";

class AssetManager {
    constructor({onProgress,onComplete}){
        this.items=[];
        this.onProgress=onProgress;
        this.onComplete=onComplete;
        this.preload=Preload();
        this.preload.fetch=fetch;
        this.preload.oncomplete = this.oncomplete.bind(this);
        this.preload.onprogress = this.onprogress.bind(this);
        this.preload.onfetched = this.onfetched.bind(this);
        this.preload.onerror = item => {
          console.log('preload error',item);
        }
    }
    fetch(assets,cb=()=>{}){
      this.cb=cb;
      for (var i = 0; i < assets.length; i++) {
        this.items.push(assets[i]);
      }
      this.preload.fetch(this.items.map((o)=>o.url));
    }
    getAssetSrc(id){
        var item=this.items.find((o)=>o.id===item.id);
        if (item && item.loaded) return item.loaded.blobUrl;
        return null;
    }
    oncomplete(items){
      this.onProgress(100);
      this.cb(items);
    }
    onprogress(e){
        this.onProgress(e.progress);
    }
    onfetched(item){
        var it=this.items.find((o)=>o.url===item.url);
        it.loaded=item;
        it.cb(item.blobUrl);
    }
}
export default AssetManager;
