import React, { Component } from 'react';
import 'components/Modal.scss';

class Modal extends Component {
    constructor(props){
        super(props);
        this.state={
        }
        this.content=React.createRef();
        this.handleClick=this.handleClick.bind(this);
    }
    handleClick(e){
        if (e.target === e.currentTarget) this.props.onClose();
    }
    render() {
        let classes="modal";
        if (this.props.open) classes+=" show";
        if (this.props.mobile) classes+=" mobile";
        return <div className={classes} onClick={this.handleClick}>
        <div className="modal-container" ref={this.content}>
            <h3 className="title">{this.props.title}</h3>
            <div className="content">{this.props.content}</div>
            <div className='modal-close' onClick={this.props.onClose}></div>
        </div>
        </div>;
    }
}
export default Modal;
