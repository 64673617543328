import React, {useContext} from "react";
import "./Presentation.scss";
import { ModelContext } from "providers/ModelProvider";
import ReactMarkdown from 'react-markdown';
import Utils from 'utils/Utils';

function Presentation() {
  const { modele,appState } = useContext(ModelContext);
  const { Presentation } = modele;
  const titrePrincipal=Utils.trad(Presentation,'Titre_Principal',appState.lang);
  const titre=Utils.trad(Presentation,'Titre',appState.lang);
  const texte=Utils.trad(Presentation,'Texte',appState.lang);
  return <div className='presentation'>
    <div className='presentation-inner'>
      <div className='presentation-titre-principal'>
        {titrePrincipal}
      </div>
      <div className="presentation-logo"/>
      <div className='presentation-titre'>
        {titre}
      </div>
      <div className='presentation-texte'>
        <ReactMarkdown>{texte}</ReactMarkdown>
      </div>
    </div>
  </div>;
}

export default Presentation;
