import React, {useContext} from "react";
import "./Reflexions.scss";
import { ModelContext } from "providers/ModelProvider";
import ReactMarkdown from 'react-markdown';
import { DateTime } from "luxon";
import Utils from 'utils/Utils';

function Reflexions() {
  const { modele,appState } = useContext(ModelContext);
  const { ReflexionsRub, Reflexions } = modele;
  const titre=Utils.trad(ReflexionsRub,'Titre',appState.lang);
  const texte=Utils.trad(ReflexionsRub,'Texte',appState.lang);
  return <div className='reflexions'>
  <div className='reflexions-left'>
    <div className="reflexions-rub-logo"/>
    <div className='reflexions-rub-titre'>
      {titre}
    </div>
    <div className='reflexions-rub-texte'>
      <ReactMarkdown>{texte}</ReactMarkdown>
    </div>
  </div>
  <div className='reflexions-inner'>
    <div className='reflexions-top'>
    <div className="reflexions-rub-logo"/>
    <div className='reflexions-rub-titre'>
      {titre}
    </div>
    <div className='reflexions-rub-texte'>
      <ReactMarkdown>{texte}</ReactMarkdown>
    </div>
    </div>
    {Reflexions.map((reflexion)=><div key={reflexion._id} className="reflexion">
      <div className='reflexions-date'>
        {DateTime.fromISO(reflexion.date).setLocale(appState.lang).toFormat('cccc')}
        <span>{DateTime.fromISO(reflexion.date).setLocale(appState.lang).toLocaleString(DateTime.DATE_FULL)}</span>
      </div>
      <div className='reflexions-titre'>
        {Utils.trad(reflexion,'Titre',appState.lang)}
      </div>
      <div className='reflexions-texte'>
        <ReactMarkdown>{Utils.trad(reflexion,'Texte',appState.lang)}</ReactMarkdown>
      </div>
      <div className="reflexions-logo-noir"/>
    </div>)}
  </div>
  </div>;
}

export default Reflexions;
