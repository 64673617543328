import React, {useContext} from "react";
import "./Modal.scss";
import { ModelContext } from "providers/ModelProvider";
import ReactMarkdown from 'react-markdown';
import Utils from 'utils/Utils';

function Modal({oeuvre,show,onClose}) {
  const { appState } = useContext(ModelContext);
  return <div className={'oeuvre-modal'+(show && ['xs','sm'].indexOf(appState.screenSize)!==-1 ? ' show' : '')} onClick={(e)=>{e.stopPropagation();onClose();}}>
    <div className='modal-inner' onClick={(e)=>{e.stopPropagation();}}>
      <div className="texte">
        <ReactMarkdown>{Utils.trad(oeuvre,'Texte_droite',appState.lang)}</ReactMarkdown>
      </div>
      <div className="close" onClick={(e)=>{e.stopPropagation();onClose();}}/>
    </div>
  </div>;
}

export default Modal;
