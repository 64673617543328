import React, {useContext} from "react";
import "./Residences.scss";
import { ModelContext } from "providers/ModelProvider";
import ReactMarkdown from 'react-markdown';
import Conf from 'Conf';
import Utils from 'utils/Utils';

function Residences() {
  const { modele,file,appState } = useContext(ModelContext);
  const { ResidencesRub, Residences } = modele;
  return <div className='residences'>
  <div className='residences-left'>
    <div className="residences-rub-logo"/>
    <div className='residences-rub-titre'>
      {Utils.trad(ResidencesRub,'Titre',appState.lang)}
    </div>
    <div className='residences-rub-texte'>
      <ReactMarkdown>{Utils.trad(ResidencesRub,'Texte',appState.lang)}</ReactMarkdown>
    </div>
  </div>
  <div className='residences-inner'>
    <div className='residences-top'>
      <div className="residences-rub-logo"/>
      <div className='residences-rub-titre'>
        {Utils.trad(ResidencesRub,'Titre',appState.lang)}
      </div>
      <div className='residences-rub-texte'>
        <ReactMarkdown>{Utils.trad(ResidencesRub,'Texte',appState.lang)}</ReactMarkdown>
      </div>
    </div>
    {Residences.map((residence)=><div key={residence._id} className="residence">
      <div className='residences-image'>
        {residence.Image && <img crossOrigin='anonymous' src={file(Conf.cockpitHost+'/api/cockpit/image?token='+Conf.cockpitToken+'&src='+residence.Image._id+'&w=1000&h=350&m=thumbnail&o=true')} alt=""/>}
      </div>
      <div className='residences-titre'>
        {Utils.trad(residence,'Titre',appState.lang)}
      </div>
      <div className='residences-texte'>
        <ReactMarkdown>{Utils.trad(residence,'Texte',appState.lang)}</ReactMarkdown>
      </div>
    </div>)}
  </div>
  </div>;
}

export default Residences;
