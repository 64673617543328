import React, { useContext } from 'react';
import 'components/LangSelector.scss';
import { ModelContext } from "providers/ModelProvider";

function LangSelector ({className}){
  const { appState,setAppState } = useContext(ModelContext);
  const { lang } = appState;
  const toggleLang=()=>{
    setAppState((state)=>{
      if (state.lang==='fr') return {...state,lang:'en'}
      else return {...state,lang:'fr'}
    })
  }
  return <div className={className}>
    <span className="togglelang" onClick={toggleLang}>{lang==='fr' ? 'fr' : <span className="gris">fr</span>}</span>
    &nbsp;
    <span className="togglelang" onClick={toggleLang}>{lang==='en' ? 'en' : <span className="gris">en</span>}</span>
  </div>;
}
export default LangSelector;
