import React, {useContext,useEffect,useRef} from "react";
import "./Topbar.scss";
import { Link, useLocation } from "react-router-dom";
import { ModelContext } from "providers/ModelProvider";
import Utils from 'utils/Utils';
import LangSelector from 'components/LangSelector';

function Topbar() {
  const { modele,appState,setAppState } = useContext(ModelContext);
  const {pathname}=useLocation();
  const {menuOpen}=appState;
  const currentPath=useRef(null);
  const setMenuOpen=(cb)=>{
    setAppState(state=>{return{...state,menuOpen:cb(state.menuOpen)}});
  }
  let l=appState.lang;
  let t=modele.Traductions;
  useEffect(()=>{
    if (currentPath.current!==pathname) setAppState(state=>{return{...state,menuOpen:false}});
    currentPath.current=pathname;
    const couleur = appState.couleur;
    const root = document.documentElement;
    root.style.setProperty('--background-color', couleur);
    if (pathname!=='/') setAppState(state=>{return{...state,landing:false}});
  },[pathname,appState.couleur,setAppState]);
  return pathname!=='/' ? <>
  <div className={"menu-mobile"+(menuOpen ? ' open' : '')}>
    <Link to={'/presentation'} className={pathname==='/presentation' ? 'active' : ''}>{Utils.trad(t,'Presentation',l)}</Link>
    <Link to={'/les-oeuvres'} className={pathname==='/les-oeuvres' ? 'active' : ''}>{Utils.trad(t,'Oeuvres',l)}</Link>
    <Link to={'/biographie'} className={pathname==='/biographie' ? 'active' : ''}>{Utils.trad(t,'Biographie',l)}</Link>
    <Link to={'/reflexions'} className={pathname==='/reflexions' ? 'active' : ''}>{Utils.trad(t,'Reflexions',l)}</Link>
    <Link to={'/residences'} className={pathname==='/residences' ? 'active' : ''}>{Utils.trad(t,'Residences',l)}</Link>
    <Link to={'/contacts'} className={pathname==='/contacts' ? 'active' : ''}>{Utils.trad(t,'Contacts',l)}</Link>
  </div>
  <div className='topbar'>
    <div className='burger' onClick={()=>setMenuOpen(state=>!state)}></div>
    <div className="title" >
      <Link to={'/'}><span onClick={()=>setAppState(state=>{return{...state,landing:true,active:null,page:1}})}>{Utils.trad(t,'Parre',l)}</span></Link>
    </div>
    <div className="menu">
      <Link to={'/presentation'} className={pathname==='/presentation' ? 'active' : ''}>{Utils.trad(t,'Presentation',l)}</Link>
      <Link to={'/les-oeuvres'} className={pathname==='/les-oeuvres' ? 'active' : ''}>{Utils.trad(t,'Oeuvres',l)}</Link>
      <Link to={'/biographie'} className={pathname==='/biographie' ? 'active' : ''}>{Utils.trad(t,'Biographie',l)}</Link>
      <Link to={'/reflexions'} className={pathname==='/reflexions' ? 'active' : ''}>{Utils.trad(t,'Reflexions',l)}</Link>
      <Link to={'/residences'} className={pathname==='/residences' ? 'active' : ''}>{Utils.trad(t,'Residences',l)}</Link>
      <Link to={'/contacts'} className={pathname==='/contacts' ? 'active' : ''}>{Utils.trad(t,'Contacts',l)}</Link>
    </div>
    <div>
      <LangSelector className="lang-selector"/>
    </div>
  </div>
  </> : null;
}

export default Topbar;
