import React, { Component } from 'react';
import 'components/Message.scss';
import Utils from 'utils/Utils';
import TextareaAutosize from 'react-autosize-textarea';
import Modal from 'components/Modal';
import ReactMarkdown from 'react-markdown';

class Message extends Component {
    constructor(props) {
        super(props);
        this.state={
            NewsletterEmail:'',
            NewsletterRgpd:false,
            ContactNom:'',
            ContactPrenom:'',
            ContactEmail:'',
            ContactSujet:'',
            ContactMessage:'',
            ContactRgpd:false,
            modalOpen:false,
        }
        this.handleSubmitNewsletter=this.handleSubmitNewsletter.bind(this);
        this.handleSubmitContact=this.handleSubmitContact.bind(this);
        this.handleChange=this.handleChange.bind(this);
        this.onModalClose=this.onModalClose.bind(this);
    }
    onModalClose(){
        this.setState({modalOpen:false});
    }
    handleChange(e){
        let k=e.target.name;
        let v=e.target.value;
        if (e.target.type==="checkbox") v=e.target.checked;
        this.setState({[k]:v});
    }
    handleSubmitNewsletter(e){
        e.preventDefault();
        this.props.sendForm(
            'Newsletter',
            {
                email:this.state.NewsletterEmail,
            },
            ()=>this.setState({
            NewsletterEmail:'',
            modalOpen:true,
        }));
    }
    handleSubmitContact(e){
        e.preventDefault();
        this.props.sendForm('Contact',{
            nom:this.state.ContactNom,
            prenom:this.state.ContactPrenom,
            email:this.state.ContactEmail,
            sujet:this.state.ContactSujet,
            message:this.state.ContactMessage,
        },()=>this.setState({
            ContactNom:'',
            ContactPrenom:'',
            ContactEmail:'',
            ContactSujet:'',
            ContactMessage:'',
            modalOpen:true,
        }));
    }
    render() {
        let trad=Utils.trad;
        let l=this.props.appState.lang;
        let message=this.props.model.Message;
        let newsletterOk=this.state.NewsletterEmail!==''
            && Utils.validateEmail(this.state.NewsletterEmail)
            && this.state.NewsletterRgpd;
        let contactOk=this.state.ContactNom!==''
            && this.state.ContactPrenom!==''
            && Utils.validateEmail(this.state.ContactEmail)
            && this.state.ContactPrenom!==''
            && this.state.ContactSujet!==''
            && this.state.ContactMessage!==''
            && this.state.ContactRgpd;
        let modalTitle=trad(message,'Confirmation_Titre',l);
        let modalContent=<ReactMarkdown>{trad(message,'Confirmation_Texte',l)}</ReactMarkdown>;
        return <div className="message">
            <div className="main-container">
                <div className="header">
                    <h1>{trad(message,'Titre_principal',l)}</h1>
                </div>
                <div className="newsletter">
                    <h3>{trad(message,'Newsletter_Titre',l)}</h3>
                    <ReactMarkdown source={trad(message,'Newsletter_Texte',l)} />
                    <form onSubmit={this.handleSubmitNewsletter}>
                    <input type="text" name="NewsletterEmail" value={this.state.NewsletterEmail} placeholder={trad(message,'Newsletter_Placeholder_Email',l)} onChange={this.handleChange}/>
                    <div className="btns">
                      <label className="checkbox-container">
                        <input type="checkbox" name="NewsletterRgpd" checked={this.state.NewsletterRgpd} onChange={this.handleChange}/>
                        <span className="checkmark"></span>
                      </label>
                      <div className="rgpd"><ReactMarkdown>{trad(message,'Newsletter_Texte_rgpd',l)}</ReactMarkdown></div>
                      <input type="submit" disabled={!newsletterOk} name="send" value={trad(message,'Newsletter_Texte_bouton',l)}/>
                    </div>
                  </form>
                </div>
                <div className="contact">
                    <h3>{trad(message,'Contact_Titre',l)}</h3>
                    <form onSubmit={this.handleSubmitContact}>
                    <table className="infos">
                    <tbody>
                    <tr>
                    <td>
                        <input type="text" name="ContactNom" value={this.state.ContactNom} placeholder={trad(message,'Contact_Placeholder_nom',l)} onChange={this.handleChange}/>
                    </td>
                    <td>
                        &nbsp;&nbsp;
                    </td>
                    <td>
                        <input type="text" name="ContactPrenom" value={this.state.ContactPrenom} placeholder={trad(message,'Contact_Placeholder_prenom',l)} onChange={this.handleChange}/>
                    </td>
                    <td>
                        &nbsp;&nbsp;
                    </td>
                    <td>
                        <input type="text" name="ContactEmail" value={this.state.ContactEmail} placeholder={trad(message,'Contact_Placeholder_email',l)} onChange={this.handleChange}/>
                    </td>
                    </tr>
                    </tbody>
                    </table>
                    <input type="text" name="ContactSujet" value={this.state.ContactSujet} placeholder={trad(message,'Contact_Placeholder_sujet',l)} onChange={this.handleChange}/>
                    <TextareaAutosize  name="ContactMessage" value={this.state.ContactMessage} placeholder={trad(message,'Contact_Placeholder_message',l)} onChange={this.handleChange}></TextareaAutosize>
                    <div className="btns">
                      <label className="checkbox-container">
                        <input type="checkbox" name="ContactRgpd" onChange={this.handleChange}/>
                        <span className="checkmark"></span>
                      </label>
                      <div className="rgpd"><ReactMarkdown>{trad(message,'Contact_Texte_rgpd',l)}</ReactMarkdown></div>
                      <input type="submit" disabled={!contactOk} name="send" value={trad(message,'Contact_Texte_bouton',l)}/>
                    </div>
                    </form>
                </div>
            </div>
            <Modal title={modalTitle} content={modalContent} open={this.state.modalOpen} onClose={this.onModalClose}/>
        </div>;
    }
}
export default Message;
