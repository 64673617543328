const Conf={
    base: process.env.NODE_ENV === 'production' ? '' : '',
    assetsUrl: "https://www.parre.fr/cockpit/storage/uploads",
    baseUrl: "https://www.parre.fr/",
    publicUrl: "https://www.parre.fr/",
    timeBeforeFade:3000,
    width:1920,
    height:990,
    delta:100,
    cockpitHost:"https://www.parre.fr/cockpit",
    cockpitToken:"d6bfd929561e891ba17a722df87ab7",
    minLoaderTime:2000,
    mobileWidth:375,
    mobileHeight:640,
}
export default Conf;
