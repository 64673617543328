import Queue from 'queue';

export default function fetch(list) {
	return new Promise((resolve, reject) => {
		this.loaded = list.length
    const q=new Queue({concurrency:1});
		for (let item of list) {
			this.state.push({ url: item })
      q.push((cb)=>{
        this.preloadOne(item, item => {
					this.onfetched(item)
  				this.loaded--
  				if (this.loaded === 0) {
  					this.oncomplete(this.state)
  					resolve(this.state)
  				}
          cb();
  			})
      });
		}
		q.start();
	})
}
