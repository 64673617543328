import React, {useContext,useState,useEffect,useCallback} from "react";
import "./Oeuvres.scss";
import { ModelContext } from "providers/ModelProvider";
import Conf from 'Conf';
import ReactMarkdown from 'react-markdown';
import Utils from 'utils/Utils';
import Modal from 'components/Oeuvres/Modal';

function Detail({oeuvre,active,showModal,onCloseModal}) {
  const { file,appState,setAppState } = useContext(ModelContext);
  const [ show,setShow ] = useState(false);
  const [ wait,setWait ] = useState(true);
  const [ showVideo,setShowVideo ] = useState(true);
  useEffect(()=>{
    let timer=null;
    if (active) {
      setShow(true);
    } else {
      timer=setTimeout(()=>setShow(false),500);
    }
    return ()=>clearTimeout(timer);
  },[active]);
  const setActive=useCallback((v)=>{
    setAppState(state=>{return{...state,active:v}});
  },[setAppState]);
  const handleEnded=(e)=>{
    const video=e.target;
    const duree=isNaN(parseInt(oeuvre.Duree_noir)) ? 1000 : parseInt(oeuvre.Duree_noir);
    setShowVideo(false);
    setTimeout(()=>{
      video.play()
    },duree);
  }
  const handlePlay=(e)=>{
    setShowVideo(true);
    setWait(false);
  }
  return <div className='detail'>
  <div className='detail-inner'>
    {show && <>
      <div className="texte-gauche">
        <div className="texte-inner">
          <ReactMarkdown>{Utils.trad(oeuvre,'Texte_gauche',appState.lang)}</ReactMarkdown>
        </div>
      </div>
      {oeuvre.Video && <div className="tableau" onClick={(e)=>e.stopPropagation()}>
        <div className="tableau-wrapper">
          <div className="tableau-container">
            <div className="ombre"/>
            <div className="video-background"/>
            <video className={showVideo?'show':''} src={file(Conf.assetsUrl+oeuvre.Video.path)} autoPlay={true} onPlay={handlePlay} onEnded={handleEnded} crossOrigin="true"/>
            {wait && oeuvre.Vignette_video && <img alt='' src={file(Conf.assetsUrl+oeuvre.Vignette_video.path)} crossOrigin="true"/>}
            <div className="cadre" style={{backgroundImage: `url(${oeuvre.Cadre_noir ? file('/images/cadre_noir.png') : file('/images/cadre.png')})`}}/>
            {['xs','sm'].indexOf(appState.screenSize)===-1 && <div className="close" onClick={(e)=>{e.stopPropagation();setActive(null);}}></div>}
          </div>
        </div>
      </div>}
      <div className="texte-droite">
        <div className="texte-inner">
          <ReactMarkdown>{Utils.trad(oeuvre,'Texte_droite',appState.lang)}</ReactMarkdown>
        </div>
      </div>
      <Modal oeuvre={oeuvre} show={showModal} onClose={onCloseModal}/>
    </>}
  </div>
  </div>;
}

export default Detail;
